import React from "react";
import * as Icon from "react-feather";
import BASE from "../configs/BASE";
import Product from "../assets/img/rover_icon/Product.svg";
import crm from "../assets/img/rover_icon/crm.svg";
import accounts from "../assets/img/rover_icon/accounts.svg";
import stocks from "../assets/img/rover_icon/stocks.svg";
import employee from "../assets/img/rover_icon/employee.svg";
import settings from "../assets/img/rover_icon/settings.svg";
import userIcon from "../assets/img/rover_icon/user.svg";
import recovery from "../assets/img/rover_icon/recovery.svg";
import circle from "../assets/img/rover_icon/circle.svg";
import distribution from "../assets/img/rover_icon/distribution.svg";
import Sales from "../assets/img/rover_icon/marketing.svg";
//import userImage from "../assets/img/portrait/small/Capture.PNG";
//import userImage2 from "../assets/img/portrait/small/masud2.jpg";

const divStyle = {
  marginRight: "10px",
  width: "25px",
  height: "25px",
};
const circleStyle = {
  marginRight: "10px",
  width: "15px",
  height: "15px",
};

const getAuth = () => {
  let authenticatedUser = localStorage.getItem("user");
  if (authenticatedUser) {
    let user = JSON.parse(authenticatedUser);
    if (user && user.auth && user.auth.isAuth) {
      localStorage.setItem("userRole", "Allow");
    }
  }
};
//let Img = <img src={userImage}/>
getAuth();

const horizontalMenuConfig = [
  {
    id: "Dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: [localStorage.getItem("userRole")],
    navLink: "/",
  },

  {
    id: "sel",
    title: "Sales",
    type: "collapse",
    icon: <img src={Sales} style={divStyle} alt="" />,
    children: [
      {
        id: "slrpt",
        title: "Sales Reports",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],
        navLink: "/slrpt",
      },
      // {
      //   id: "pos0",
      //   title: "POS",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} alt=""/>,
      //   permissions: [localStorage.getItem("userRole")],
      //   navLink: "/pos0",
      // },
      {
        id: "cslgr",
        title: "Customer",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],
        navLink: "/cslgr",
      },

      {
        id: "chal",
        title: "Sales",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/chal",
      },
      {
        id: "squot",
        title: "New Quotation",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/squot",
      },

      {
        id: "qtsls",
        title: "Quotation List",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/qtsls",
      },

      {
        id: "retn",
        title: "Sales Return",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/retn",
      },
      {
        id: "bksl",
        title: "Booklist Sales",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/bksl",
      },
      {
        id: "spsal",
        title: "Specimen Copy",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/spsal",
      },
      {
        id: "recvs",
        title: "Money Receive",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/recvs",
      },

      {
        id: "curin",
        title: "Curier Info",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        navLink: "/curin",
      },

      {
        id: "curbo",
        title: "Curier Booking",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/curbo",
      },
      // {
      //   id: "ordr",
      //   title: "Order",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} />,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/ordr",
      // },

      // {
      //   id: "ortr",
      //   title: "Order Tracking",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} />,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/ortr",
      // },

      // {
      //   id: "canc",
      //   title: "Cancel",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} />,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/canc",
      // },
    ],
  },
  {
    id: "dis",
    title: "Purchase",
    type: "collapse",
    icon: <img src={distribution} style={divStyle} alt="" />,
    children: [
      {
        id: "pcrpt",
        title: "Purchase Reports",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        navLink: "/pcrpt",
      },
      {
        id: "suplgr",
        title: "Supplier",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],
        navLink: "/suplgr",
      },
      {
        id: "purc",
        title: "Purchase",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],
        navLink: "/purc",
      },
      {
        id: "prnt",
        title: "Purchase Return",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],
        navLink: "/prnt",
      },
      {
        id: "stor",
        title: "Store",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        navLink: "/stor",
      },
      // {
      //   id: "strc",
      //   title: "Store Requisition",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} />,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/strc",
      // },

      // {
      //   id: "stmv",
      //   title: "Stock Movement",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} />,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/stmv",
      // },
      {
        id: "phst",
        title: "Opening Stock",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/phst",
      },
      // {
      //   id: "prec",
      //   title: "Products Receive",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} />,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/prec",
      // },
      // {
      //   id: "deag",
      //   title: "Delivery Agent",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} alt="" />,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/deag",
      // },
      // {
      //   id: "degt",
      //   title: "Delivery Agent Type",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} alt="" />,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/degt",
      // },
      // {
      //   id: "agch",
      //   title: "Agent Charge",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} alt=""/>,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/agch",
      // },
      // {
      //   id: "deen",
      //   title: "Delivery Entry",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} />,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/deen",
      // },

      // {
      //   id: "debl",
      //   title: "Delivery Bill",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} />,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/debl",
      // },
      // {
      //   id: "shcc",
      //   title: "Shipping Charge Costing",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} />,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/shcc",
      // },

      // {
      //   id: "bill",
      //   title: "Bill",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} />,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/bill",
      // },

      {
        id: "clim",
        title: "Claim",
        type: "item",
        icon: <img src={circle} style={circleStyle} />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/clim",
      },
    ],
  },

  {
    id: "crm",
    title: "CRM",
    type: "collapse",
    icon: <img src={crm} style={divStyle} alt="" />,
    children: [
      {
        id: "scho",
        title: "School",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/scho",
      },
      {
        id: "cuin",
        title: "Customer Info",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/cuin",
      },

      // {
      //   id: "cudm",
      //   title: "Customer Data Management",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} />,
      //   navLink: "/cudm",
      // },
      {
        id: "noti",
        title: "Notification",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/noti",
      },

      {
        id: "demr",
        title: "Demarcation",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/demr",
      },
      // {
      //   id: "nodt",
      //   title: "Notification Details",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} />,
      //   permissions: [localStorage.getItem("userName")],

      //   navLink: "/nodt",
      // },
      // {
      //   id: "oftp",
      //   title: "Offer Type",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} />,
      //   permissions: [localStorage.getItem("userName")],

      //   navLink: "/oftp",
      // },

      // {
      //   id: "offr",
      //   title: "Offer",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} />,
      //   permissions: [localStorage.getItem("userName")],

      //   navLink: "/offr",
      // },
      // {
      //   id: "ofdt",
      //   title: "Offer Details",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} />,
      //   permissions: [localStorage.getItem("userName")],

      //   navLink: "/ofdt",
      // },
    ],
  },

  {
    id: "pro",
    title: "Products",
    type: "collapse",
    icon: <img src={Product} style={divStyle} alt="" />,
    children: [
      {
        id: "prrpt",
        title: "Product Reports",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/prrpt",
      },
      {
        id: "prge",
        title: BASE.prge,
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/prge",
      },
      {
        id: "prbr",
        title: BASE.prbr,
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/prbr",
      },
      {
        id: "prca",
        title: BASE.prca,
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],
        navLink: "/prca",
      },
      {
        id: "prvn",
        title: BASE.prvn,
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        navLink: "/prvn",
      },
      // {
      //   id: "film",
      //   title: "Filter Master",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle}  alt=""/>,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/film",
      // },
      // {
      //   id: "fivl",
      //   title: "Filter Value ",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle}  alt=""/>,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/fivl",
      // },
      {
        id: "psku",
        title: BASE.psku,
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],
        navLink: "/psku",
      },
      {
        id: "pskt",
        title: BASE.pskt,
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],
        navLink: "/pskt",
      },

      {
        id: "pmanut",
        title: BASE.pmanut,
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/pmanut",
      },
      {
        id: "blst",
        title: "Booklist",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],
        navLink: "/blst",
      },
      // {
      //   id: "prfl",
      //   title: "Product Filter",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} alt=""/>,
      //   permissions: [localStorage.getItem("userRole")],
      //   navLink: "/prfl",
      // },
      {
        id: "unit",
        title: "Unit",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/unit",
      },

      // {
      //   id: "uncn",
      //   title: "Unit Conversion",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} alt="" />,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/uncn",
      // },
      {
        id: "prra",
        title: "Product Rate",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/prra",
      },

      {
        id: "filup",
        title: "Upload File",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/filup",
      },

      // {
      //   id: "bomd",
      //   title: "BOM",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} alt=""/>,
      //   navLink: "/bomd",
      // },
      // {
      //   id: "brco",
      //   title: "Barcode",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} alt=""/>,
      //   navLink: "/brco",
      // },
      // {
      //   id: "bach",
      //   title: "Batch",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} alt="" />,
      //   navLink: "/bach",
      // },
      // {
      //   id: "stra",
      //   title: "Standard Rate",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} alt=""/>,
      //   navLink: "/stra",
      // },
    ],
  },
  {
    id: "acc",
    title: "Accounts",
    type: "collapse",
    icon: <img src={accounts} style={divStyle} alt="" />,
    children: [
      {
        id: "accrpt",
        title: " Account Reports",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/accrpt",
      },
      {
        id: "acgr",
        title: "Account Group",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/acgr",
      },
      {
        id: "ledg",
        title: "Legder",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/ledg",
      },

      {
        id: "crlmt",
        title: "Credit Limit",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/crlmt",
      },
      {
        id: "vctp",
        title: "Voucher Type",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/vctp",
      },
      {
        id: "fiyr",
        title: "Financial Year",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/fiyr",
      },
      {
        id: "revc",
        title: "Receipt Voucher",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/revc",
      },
      {
        id: "pavc",
        title: "Payment Voucher",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/pavc",
      },
      {
        id: "jovc",
        title: "Journal Voucher",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/jovc",
      },

      {
        id: "cnvc",
        title: "Contra Voucher",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/cnvc",
      },
      // {
      //   id: "adaw",
      //   title: "Daily Wages",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} alt="" />,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/adaw",
      // },
      // {
      //   id: "amsa",
      //   title: "Monthly Salary",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} alt=""/>,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/amsa",
      // },
      // {
      //   id: "adva",
      //   title: "Advance Payment",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} alt="" />,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/adva",
      // },

      {
        id: "bnre",
        title: "Bank Reconciliation",
        type: "item",
        icon: <img src={circle} style={circleStyle} alt="" />,
        permissions: [localStorage.getItem("userRole")],

        navLink: "/bnre",
      },
      // {
      //   id: "curr",
      //   title: "Currency",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} alt=""/>,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/curr",
      // },
      // {
      //   id: "cucn",
      //   title: "Currency Convertion",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} alt="" />,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/cucn",
      // },
      // {
      //   id: "vttp",
      //   title: "Vat Types",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} alt=""/>,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/vttp",
      // },
      // {
      //   id: "taxs",
      //   title: "Tax",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} alt="" />,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/taxs",
      // },

      // {
      //   id: "pbtx",
      //   title: "Purchase Bill Tax",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} alt="" />,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/pbtx",
      // },

      // {
      //   id: "prtx",
      //   title: "Purchase Return Tax",
      //   type: "item",
      //   icon: <img src={circle} style={circleStyle} alt="" />,
      //   permissions: [localStorage.getItem("userRole")],

      //   navLink: "/prtx",
      // },
    ],
  },
  // {
  //   id: "emp",
  //   title: "Employee",
  //   type: "collapse",
  //   icon: <img src={employee} style={divStyle} alt="" />,
  //   children: [
  //     {
  //       id: "emtp",
  //       title: "Type",
  //       type: "item",
  //       icon: <img src={circle} style={circleStyle} alt=""/>,
  //       permissions: [localStorage.getItem("userRole")],

  //       navLink: "/emtp",
  //     },
  //     {
  //       id: "dept",
  //       title: "Department",
  //       type: "item",
  //       icon: <img src={circle} style={circleStyle} alt=""/>,
  //       permissions: [localStorage.getItem("userRole")],

  //       navLink: "/dept",
  //     },
  //     {
  //       id: "dign",
  //       title: "Designation",
  //       type: "item",
  //       icon: <img src={circle} style={circleStyle} alt=""/>,
  //       permissions: [localStorage.getItem("userRole")],

  //       navLink: "/dign",
  //     },
  //     {
  //       id: "emin",
  //       title: "Employee Info",
  //       type: "item",
  //       icon: <img src={circle} style={circleStyle} alt="" />,
  //       permissions: [localStorage.getItem("userRole")],

  //       navLink: "/emin",
  //     },
  //     {
  //       id: "emsc",
  //       title: "Schedule",
  //       type: "item",
  //       icon: <img src={circle} style={circleStyle} alt=""/>,
  //       permissions: [localStorage.getItem("userRole")],

  //       navLink: "/emsc",
  //     },

  //     {
  //       id: "epsc",
  //       title: "Employee Schedule",
  //       type: "item",
  //       icon: <img src={circle} style={circleStyle} alt=""/>,
  //       permissions: [localStorage.getItem("userRole")],

  //       navLink: "/epsc",
  //     },
  //     {
  //       id: "lems",
  //       title: "Leave Master",
  //       type: "item",
  //       icon: <img src={circle} style={circleStyle} alt=""/>,
  //       permissions: [localStorage.getItem("userRole")],

  //       navLink: "/lems",
  //     },
  //     {
  //       id: "lset",
  //       title: "Employee Leave Setings",
  //       type: "item",
  //       icon: <img src={circle} style={circleStyle} alt=""/>,
  //       permissions: [localStorage.getItem("userRole")],

  //       navLink: "/lset",
  //     },
  //     {
  //       id: "hode",
  //       title: "Holiday Declaration",
  //       type: "item",
  //       icon: <img src={circle} style={circleStyle} alt=""  />,
  //       navLink: "/hode",
  //     },

  //     {
  //       id: "hose",
  //       title: "Holiday Setup",
  //       type: "item",
  //       icon: <img src={circle} style={circleStyle} alt="" />,
  //       navLink: "/hose",
  //     },
  //     {
  //       id: "aten",
  //       title: "Attendance",
  //       type: "item",
  //       icon: <img src={circle} style={circleStyle} alt="" />,
  //       permissions: [localStorage.getItem("userRole")],

  //       navLink: "/aten",
  //     },
  //     {
  //       id: "leap",
  //       title: "Leave Approved",
  //       type: "item",
  //       icon: <img src={circle} style={circleStyle} alt="" />,
  //       permissions: [localStorage.getItem("userRole")],

  //       navLink: "/leap",
  //     },
  //     {
  //       id: "pahe",
  //       title: "Pay Head",
  //       type: "item",
  //       icon: <img src={circle} style={circleStyle} alt="" />,
  //       permissions: [localStorage.getItem("userRole")],

  //       navLink: "/pahe",
  //     },
  //     {
  //       id: "sapk",
  //       title: "Salary Package",
  //       type: "item",
  //       icon: <img src={circle} style={circleStyle} alt="" />,
  //       permissions: [localStorage.getItem("userRole")],

  //       navLink: "/sapk",
  //     },
  //     {
  //       id: "sade",
  //       title: "Salary Detail",
  //       type: "item",
  //       icon: <img src={circle} style={circleStyle} alt="" />,
  //       permissions: [localStorage.getItem("userRole")],

  //       navLink: "/sade",
  //     },
  //     // {
  //     //   id: "dawa",
  //     //   title: "Daily Wages",
  //     //   type: "item",
  //     //   icon: <img src={circle} style={circleStyle} alt="" />,
  //     //   permissions: [localStorage.getItem("userRole")],

  //     //   navLink: "/dawa",
  //     // },
  //     {
  //       id: "bode",
  //       title: "Bonus Deduction",
  //       type: "item",
  //       icon: <img src={circle} style={circleStyle} alt="" />,
  //       permissions: [localStorage.getItem("userRole")],

  //       navLink: "/bode",
  //     },
  //     {
  //       id: "mosa",
  //       title: "Monthly Salary",
  //       type: "item",
  //       icon: <img src={circle} style={circleStyle} alt=""/>,
  //       permissions: [localStorage.getItem("userRole")],

  //       navLink: "/mosa",
  //     },
  //   ],
  // },
  {
    id: "settings",
    title: "Settings",
    type: "collapse",
    icon: <img src={settings} style={divStyle} alt="" />,
    children: [
      {
        id: "usr",
        title: "User Panel",
        type: "collapse",
        icon: <img src={userIcon} style={circleStyle} alt="" />,
        children: [
          {
            id: "usin",
            title: "User",
            type: "item",
            icon: <img src={circle} style={circleStyle} alt="" />,
            permissions: [localStorage.getItem("userRole")],

            navLink: "/usin",
          },
          // {
          //   id: "menu",
          //   title: "Menu",
          //   type: "item",
          //   icon: <img src={circle} style={circleStyle} alt="" />,
          //   permissions: [localStorage.getItem("userRole")],

          //   navLink: "/menu",
          // },
          // {
          //   id: "perm",
          //   title: "Permission",
          //   type: "item",
          //   icon: <img src={circle} style={circleStyle} alt="" />,
          //   permissions: [localStorage.getItem("userRole")],

          //   navLink: "/perm",
          // },
          // {
          //   id: "uspr",
          //   title: "User Permission",
          //   type: "item",
          //   icon: <img src={circle} style={circleStyle} alt="" />,
          //   permissions: [localStorage.getItem("userRole")],

          //   navLink: "/uspr",
          // },
          {
            id: "vnot",
            title: "Voice Note",
            type: "item",
            icon: <img src={circle} style={circleStyle} alt="" />,
            permissions: [localStorage.getItem("userRole")],

            navLink: "/vnot",
          },
        ],
      },
      {
        id: "recov",
        title: "Recovery",
        type: "collapse",
        icon: <img src={recovery} style={circleStyle} alt="" />,
        children: [
          {
            id: "rinv",
            title: "Invoice Recovery",
            type: "item",
            icon: <img src={circle} style={circleStyle} alt="" />,
            permissions: [localStorage.getItem("userRole")],

            navLink: "/rinv",
          },
        ],
      },
    ],
  },

  // {
  //   id: "",
  // },
  // {
  //   id: "uspr0",

  //   icon:

  //   <img
  //   src={userImage2}
  //   //paddingLeft="150"
  //   height="600"
  //   width="230"
  //   alt=""/>,

  // }
];

export default horizontalMenuConfig;
