// const BASE = {
//   URL: "https://localhost:44358",
// };

const BASE = {
  // applicarion url
  URL: document.getElementById("root").getAttribute("domain"),

  SFUR: "",
  // product search
  productCode: "Code",
  sKU_Cod: "Year",
  bR_Nm: "Type",
  cT_Nm: "Variety",
  mNF_Nm: "Name",
  gEN_Nm: "Land Size",
  mNF_Cod: "Demo Code",

  // router menu
  prge: "Land Size",
  prbr: "Demo Type",
  prca: "Variety",
  prvn: "Name",
  psku: "Demo Entry",
  pskt: "Edition Transfer",
  pmanut: "Demo Transfer",
  skudl: "Demo Delete",
};

export default BASE;
