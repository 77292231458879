import React, { createContext, useReducer, useEffect } from "react";
import axios from "axios";
import authReducer from "../reducers/authReducer";

import baseConfig from "../configs/BASE";
import moment from "moment";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [user, dispatch] = useReducer(authReducer, {}, () => {
    const localData = localStorage.getItem("user");
    return localData ? JSON.parse(localData) : {};
  });

  const checkPhone = (phone) => {
    const baseUrl = baseConfig.URL;
    const url = `${baseUrl}/api/FOTP3205/OTPVER/${phone}/login`;
    axios({
      method: "get",
      url: `${url}`,
    })
      .then((res) => {
        if (res.status === 200) {
          //let otp = CryptoJS.AES.encrypt(JSON.stringify(res.data[0].oTP_Code), 'ambit00005@123').toString();
          let otp = res.data[0].oTP_Code;
          let userId = res.data[0].lOG_In_ID;
          let userType = res.data[0].tYP;
          let userName = res.data[0].uS_Nm;
          let comName = res.data[0].comName;
          let comAdd = res.data[0].comAdd;
          let comPho = res.data[0].comPho;

          let s_Id = res.data[0].s_Id;
          let p_Id = res.data[0].p_Id;
          let useAs = res.data[0].useAs;
          let check = userId ? true : false;
          let logInAt = moment(new Date());
          let fR_Dat = moment(res.data[0].fR_Dat).format("DD-MM-YYYY");
          let tO_Dat = moment(res.data[0].tO_Dat).format("DD-MM-YYYY");
          let checkTime = Date.now();

          if (check) {
            dispatch({
              type: "CHECK_PHONE",
              payload: {
                check,
                otp,
                userId,
                userType,
                userName,
                comName,
                comAdd,
                comPho,
                s_Id,
                p_Id,
                useAs,
                logInAt,
                checkTime,
              },
            });

            dispatch({
              type: "FNYEAR",
              payload: { fR_Dat, tO_Dat },
            });
          }
        }
      })
      .catch((err) => {
        //console.log("otp err", err);
      });
  };
  const checkLogin = (
    userName,
    userType,
    logInAt,
    userId,
    s_Id,
    p_Id,
    useAs
  ) => {
    // let authenticatedUser = localStorage.getItem("user");
    // if (authenticatedUser) {
    //   let userId = JSON.parse(authenticatedUser);
    //   if(userId.checkPhone && userId.checkPhone.userId) {

    //     let logInTime=  userId.checkPhone.logInAt;
    //     var now = moment(new Date());
    //     var duration = moment.duration(now.diff(logInTime));
    //     var minute = duration.asMinutes();

    //     if(minute>30)
    //     {
    //       localStorage.removeItem("user");
    //       localStorage.removeItem("challan");
    //       localStorage.removeItem("grid");
    //       localStorage.setItem("userRole",   ""    );

    //     }
    //    else{

    let loginData = {
      isAuth: true,
      name: userName,
      userType: userType,
      userId: userId,
      s_Id: s_Id,
      p_Id: p_Id,
      useAs: useAs,
      logInAt: logInAt,
    };

    dispatch({
      type: "LOGIN",
      payload: loginData,
    });
  };

  const logOut = () => {
    dispatch({
      type: "LOGOUT",
      payload: {},
    });

    localStorage.removeItem("sales");
    localStorage.removeItem("user");
    localStorage.removeItem("purchase");
    localStorage.removeItem("salesreturn");
    localStorage.removeItem("purchaseReturn");
    localStorage.removeItem("grid");
    localStorage.setItem("userRole", "");
    localStorage.setItem("collapse", " ");
    localStorage.setItem("AP", " ");
    sessionStorage.removeItem("sales");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("purchase");
    sessionStorage.removeItem("salesreturn");
    sessionStorage.removeItem("purchaseReturn");
    sessionStorage.removeItem("grid");
    sessionStorage.setItem("userRole", "");
    sessionStorage.setItem("collapse", " ");
    sessionStorage.setItem("AP", " ");
    sessionStorage.removeItem("gridData");
    window.location.replace("/login");
  };

  const pageReset = () => {
    dispatch({
      type: "RESET",
      payload: {},
    });

    localStorage.removeItem("user");
  };

  const setFnYear = (fnYearData) => {
    dispatch({
      type: "FNYEAR",
      payload: fnYearData,
    });
  };

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [checkLogin, logOut, setFnYear]);
  return (
    <AuthContext.Provider
      value={{ user, checkLogin, checkPhone, logOut, pageReset, setFnYear }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
