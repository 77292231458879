const authReducer = (state, action) => {
  switch (action.type) {
  
    case "CHECK_PHONE":
      return {...state, checkPhone: action.payload}

    case "LOGIN":
      return { ...state, auth: action.payload };

      case "FNYEAR":
        return { ...state, fnyear: action.payload };

    case "LOGOUT":
      return action.payload;
      
    case "RESET":
      return action.payload;
      
    default:
      return state;
  }
};

export default authReducer;
