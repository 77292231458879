import mock from "../mock"
import BASE from "../../configs/BASE";

export const searchResult = [

  {
    id: 1,
    target: "home",
    title: "Home",
    link: "/",
    icon: "Home",
    starred: false
  },

  //sales Search
  {
    id: 2,
    target: "SalesReports",
    title: "Sales Reports",
    link: "/slrpt",
    starred: false
  },
  {
    id: 3,
    target: "POS",
    title: "POS",
    link: "/pos0",
    starred: false
  },
  {
    id: 4,
    target: "SalesCounter",
    title: "Sales Counter",
    link: "/coun",
    starred: false
  },

  {
    id: 5,
    target: "sales",
    title: "Sales",
    link: "/chal",
    starred: false
  },
  // {
  //   id: 5,
  //   target: "Order",
  //   title: "Order",
  //   link: "/ordr",
  // },

  // {
  //   id: 6,
  //   target: "OrderTracking",
  //   title: "Order Tracking",
  //   link: "/ortr",
  // },
  {
    id: 6,
    target: "Return",
    title: "Return",
    link: "/retn",
    starred: false
  },
  // {
  //   id: 8,
  //   target: "Cancel",
  //   title: "Cancel",
  //   link: "/canc",
  // },

  //Purchase Search
  {
    id: 7,
    target: "PurchaseReports",
    title: "Purchase Reports",
    link: "/pcrpt",
    starred: false
  },
  {
    id: 8,
    target: "Purchase",
    title: "Purchase",
    link: "/purc",
    starred: false
  },
  {
    id: 9,
    target: "PurchaseReturn",
    title: "Purchase Return",
    link: "/prnt",
    starred: false
  },
  {
    id: 10,
    target: "PhysicalStock",
    title: "Physical Stock",
    link: "/phst",
    starred: false
  },
  {
    id: 11,
    target: "Store",
    title: "Store",
    link: "/stor",
    starred: false
  },

  //CRM Search
  {
    id: 12,
    target: "CustomerInfo",
    title: "Customer Info",
    nlink: "/cuin",
    starred: false
  },

  // {
  //   id: 42,
  //   target: "CustomerDataManagement",
  //   title: "Customer Data Management",
  //   link: "/cudm",
  // },
  {
    id: 13,
    target: "Notification",
    title: "Notification",
    link: "/noti",
    starred: false
  },

  {
    id: 14,
    target: "Demarcation",
    title: "Demarcation",
    link: "/demr",
    starred: false
  },
  // {
  //   id: 45,
  //   target: "NotificationDetails",
  //   title: "Notification Details",
  //   link: "/nodt",
  // },
  // {
  //   id: 46,
  //   target: "OfferType",
  //   title: "Offer Type",
  //   link: "/oftp",
  // },

  // {
  //   id: 47,
  //   target: "Offer",
  //   title: "Offer",
  //   link: "/offr",
  // },
  // {
  //   id: 48,
  //   target: "OfferDetails",
  //   title: "Offer Details",
  //   link: "/ofdt",
  // },
  // {
  //   id: 9,
  //   target: "StoreRequisition",
  //   title: "Store Requisition",
  //   link: "/strc",
  // },

  // {
  //   id: 11,
  //   target: "StockMovement",
  //   title: "Stock Movement",
  //   link: "/stmv",
  // },
  // {
  //   id: 10,
  //   target: "ProductsReceive",
  //   title: "Products Receive",
  //   link: "/prec",
  // },
  // {
  //   id: 11,
  //   target: "DeliveryAgent",
  //   title: "Delivery Agent",
  //   link: "/deag",
  // },
  // {
  //   id: 12,
  //   target: "DeliveryAgentType",
  //   title: "Delivery Agent Type",
  //   link: "/degt",
  // },
  // {
  //   id: 15,
  //   target: "AgentCharge",
  //   title: "Agent Charge",
  //   link: "/agch",
  // },
  // {
  //   id: 16,
  //   target: "DeliveryEntry",
  //   title: "Delivery Entry",
  //   link: "/deen",
  // },
  // {
  //   id: 17,
  //   target: "DeliveryBill",
  //   title: "Delivery Bill",
  //   link: "/debl",
  // },
  // {
  //   id: 18,
  //   target: "ShippingChargeCosting",
  //   title: "Shipping Charge Costing",
  //   link: "/shcc",
  // },

  // {
  //   id: 19,
  //   target: "Bill",
  //   title: "Bill",
  //   link: "/bill",
  // },

  // {
  //   id: 20,
  //   target: "Claim",
  //   title: "Claim",
  //   link: "/clim",
  // },

  // {
  //   id: 21,
  //   target: "Adjustment",
  //   title: "Adjustment",
  //   link: "/adju",
  // },
  {
    id: 15,
    target: BASE.prge,
    title: BASE.gEN_Nm,
    link: "/prge",
    starred: false
  },
  {
    id: 16,
    target: BASE.prbr,
    title: BASE.bR_Nm,
    link: "/prbr",
    starred: false
  },
  {
    id: 17,
    target: BASE.prca,
    title: BASE.cT_Nm,
    link: "/prca",
    starred: false
  },
  {
    id: 18,
    target: BASE.prvn,
    title: BASE.mNF_Nm,
    link: "/prvn",
    starred: false
  },
  // {
  //   id: 53,
  //   target: "FilterMaster",
  //   title: "Filter Master",
  //   link: "/film",
  // },
  // {
  //   id: 54,
  //   target: "FilterValue ",
  //   title: "Filter Value ",
  //   link: "/fivl",
  // },
  {
    id: 19,
    target: BASE.psku, 
    title: BASE.sKU_Cod,
    link: "/psku",
    starred: false
  },
  {
    id: 20,
    target: BASE.pskt,
    title: "Edition Transfer",
    link: "/pskt",
    starred: false
  },
  {
    id: 21,
    target: BASE.pmanut,
    title: BASE.pmanut,
    link: "/pmanut",
    starred: false
  },
  // {
  //   id: 56,
  //   target: "ProductFilter",
  //   title: "Product Filter",
  //   nlink: "/prfl",
  // },
  {
    id: 22,
    target: "Unit",
    title: "Unit",
    link: "/unit",
    starred: false
  },

  {
    id: 23,
    target: "UnitConversion",
    title: "Unit Conversion",
    link: "/uncn",
    starred: false
  },
  {
    id: 24,
    target: "ProductRate",
    title: "Product Rate",
    link: "/prra",
    starred: false
  },

  // {
  //   id: 60,
  //   target: "BOM",
  //   title: "BOM",
  //   link: "/bomd",
  // },

  // {
  //   id: 62,
  //   target: "Barcode",
  //   title: "Barcode",
  //   link: "/brco",
  // },
  // {
  //   id: 63,
  //   target: "Batch",
  //   title: "Batch",
  //   link: "/bach",
  // },
  // {
  //   id: 64,
  //   target: "StandardRate",
  //   title: "Standard Rate",
  //   link: "/stra",
  // },

  //Accounts Search
  {
    id: 58,
    target: "AccountsReport",
    title: "Accounts Report",
    link: "/accrpt",
  },
  {
    id: 26,
    target: "AccountGroup",
    title: "Account Group",
    link: "/acgr",
    starred: false
  },
  {
    id: 27,
    target: "Legder",
    title: "Legder",
    link: "/ledg",
    starred: false
  },

  {
    id: 28,
    target: "VoucherType",
    title: "Voucher Type",
    link: "/vctp",
    starred: false
  },
  {
    id: 29,
    target: "FinancialYear",
    title: "Financial Year",
    link: "/fiyr",
    starred: false
  },
  {
    id: 30,
    target: "ReceiptVoucher",
    title: "Receipt Voucher",
    link: "/revc",
    starred: false
  },
  {
    id: 31,
    target: "PaymentVoucher",
    title: "Payment Voucher",
    link: "/pavc",
    starred: false
  },
  {
    id: 32,
    target: "JournalVoucher",
    title: "Journal Voucher",
    link: "/jovc",
    starred: false
  },
  // {
  //   id: 29,
  //   target: "SalesVoucher",
  //   title: "Sales Voucher",
  //   nlink: "/savc",
  // },
  {
    id: 33,
    target: "ContraVoucher",
    title: "Contra Voucher",
    link: "/cnvc",
    starred: false
  },
  // {
  //   id: 87,
  //   target: "Purchase",
  //   title: "Purchase",
  //   link: "/purm",
  // },
  // {
  //   id: 31,
  //   target: "DailyWages",
  //   title: "Daily Wages",
  //   link: "/adaw",
  // },
  // {
  //   id: 32,
  //   target: "MonthlySalary",
  //   title: "Monthly Salary",
  //   link: "/amsa",
  // },
  {
    id: 34,
    target: "Advance",
    title: "Advance Payment",
    link: "/adva",
    starred: false
  },

  {
    id: 35,
    target: "BankReconciliation",
    title: "Bank Reconciliation",
    link: "/bnre",
    starred: false
  },
  // {
  //   id: 35,
  //   target: "Currency",
  //   title: "Currency",
  //   link: "/curr",
  // },
  // {
  //   id: 36,
  //   target: "CurrencyConvertion",
  //   title: "Currency Convertion",
  //   link: "/cucn",
  // },
  // {
  //   id: 37,
  //   target: "VatTypes",
  //   title: "Vat Types",
  //   link: "/vttp",
  // },
  // {
  //   id: 38,
  //   target: "Tax",
  //   title: "Tax",
  //   link: "/taxs",
  // },


  // {
  //   id: 39,
  //   target: "PurchaseBillTax",
  //   title: "Purchase Bill Tax",
  //   link: "/pbtx",
  // },

  // {
  //   id: 40,
  //   target: "PurchaseReturnTax",
  //   title: "Purchase Return Tax",
  //   link: "/prtx",
   // type: "item",
  // },

  //products search

  
  //Employee Search
  {
    id: 36,
    target: "Type",
    title: "Type",
    link: "/emtp",
    starred: false
  },
  {
    id: 37,
    target: "Department",
    title: "Department",
    link: "/dept",
    starred: false
  },
  {
    id: 38,
    target: "Designation",
    title: "Designation",
    link: "/dign",
    starred: false
  },
  {
    id: 39,
    target: "EmployeeInfo",
    title: "Employee Info",
    link: "/emin",
    starred: false
  },
  {
    id: 40,
    target: "Schedule",
    title: "Schedule",
    link: "/emsc",
    starred: false
  },

  {
    id: 41,
    target: "EmployeeSchedule",
    title: "Employee Schedule",
    link: "/epsc",
    starred: false
  },
  {
    id: 42,
    target: "LeaveMaster",
    title: "Leave Master",
    link: "/lems",
    starred: false
  },
  {
    id: 43,
    target: "EmployeeLeaveSetings",
    title: "Employee Leave Setings",
    link: "/lset",
    starred: false
  },
  {
    id: 44,
    target: "HolidayDeclaration",
    title: "Holiday Declaration",
    link: "/hode",
    starred: false
  },

  {
    id: 45,
    target: "HolidaySetup",
    title: "Holiday Setup",
    link: "/hose",
    starred: false
  },
  {
    id: 46,
    target: "Attendance",
    title: "Attendance",
    link: "/aten",
    starred: false
  },
  {
    id: 47,
    target: "LeaveApproved",
    title: "Leave Approved",
    link: "/leap",
    starred: false
  },
  {
    id: 48,
    target: "PayHead",
    title: "Pay Head",
    link: "/pahe",
    starred: false
  },
  {
    id: 49,
    target: "SalaryPackage",
    title: "Salary Package",
    link: "/sapk",
    starred: false
  },
  {
    id: 50,
    target: "SalaryDetail",
    title: "Salary Detail",
    link: "/sade",
    starred: false
  },
  {
    id: 51,
    target: "DailyWages",
    title: "Daily Wages",
    link: "/dawa",
    starred: false
  },
  {
    id: 52,
    target: "BonusDeduction",
    title: "Bonus Deduction",
    link: "/bode",
    starred: false
  },
  {
    id: 53,
    target: "MonthlySalary",
    title: "Monthly Salary",
    link: "/mosa",
    starred: false
  },

  //user Search
  {
    id: 54,
    target: "UserInfo",
    title: "User Info",
    navLink: "/usin",
    starred: false
  },
  {
    id: 55,
    target: "Menu",
    title: "Menu",
    link: "/menu",
    starred: false
  },
  {
    id: 56,
    target: "Permission",
    title: "Permission",
    link: "/perm",
    starred: false
  },
  {
    id: 57,
    target: "UserPermission",
    title: "User Permission",
    link: "/uspr",
    starred: false
  }

]

mock.onGet("/api/search/bookmarks/data").reply(200, {
  searchResult
})

mock.onPost("/api/update/bookmarks").reply(request => {
  const bookmarkToUpdate = JSON.parse(request.data).obj

  searchResult.filter(i => {
    if (i.id === bookmarkToUpdate.id) {
      return (i.starred = !bookmarkToUpdate.starred)
    } else {
      return null
    }
  })
  return [200]
})
